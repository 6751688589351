import React from "react";
import Layout from "../components/Layout";
import QuickActions from "../components/QuickActions";
import Questions from "../components/Questions";
import { Link } from "gatsby";
import { graphql } from "gatsby";
import arrow from "../images/arrow_up.png";
import "./domain.sass";
import "../Styles.sass";
import contract from "../images/icons/contract.png";
import company from "../images/icons/company.png";
import hr from "../images/icons/hr.png";
import locaux from "../images/icons/locaux.png";
import debiteurs from "../images/icons/debiteurs.png";
import pi from "../images/icons/pi.png";
import immo from "../images/icons/immo.png";
import constru from "../images/icons/constru.png";
import creanciers from "../images/icons/creanciers.png";
import contentieux from "../images/icons/contentieux.png";
import abos from "../images/icons/abos.png";

const getImage = (slug) => {
  switch (slug) {
    case "constitution-societes":
      return company;
    case "vos-contrats":
      return contract;
    case "ressources-humaines":
      return hr;
    case "locaux":
      return locaux;
    case "debiteurs":
      return debiteurs;
    case "propriete-intellectuelle":
      return pi;
    case "immobilier":
      return immo;
    case "constructions":
      return constru;
    case "creanciers":
      return creanciers;
    case "contentieux":
      return contentieux;
    case "abonnement":
      return abos;
    default:
      return null;
  }
};

export default function Domain({ data }) {
  const content = data.allDomainsJson.edges[0].node;
  return (
    <Layout title={content.title} description={content.metaText}>
      <div id="header-box">
        <div id="left">
          <h5>{content.subTitle}</h5>
          <h2>{content.title}</h2>
          <p>{content.text}</p>
        </div>
        <div id="right">
          <ul>
            {content.checkboxes.map((checkbox) => (
              <li>{checkbox}</li>
            ))}
          </ul>
        </div>
      </div>
      <div id="daddy-holder">
        <div id="domain-prestations">
          {content.prestations.map((prest) => {
            return (
              <Link to={"/domaines/" + content.slug + "/" + prest.slug}>
                <div className="prestation-card">
                  <img
                    className="icon"
                    src={getImage(content.slug)}
                    alt="domain-icon"
                  ></img>
                  <h3>{prest.cardTitle}</h3>
                  <p>{prest.cardText}</p>
                  <div id="price-container">
                    <h3
                      className={
                        prest.cardPrice === "En savoir plus" ? "" : "price"
                      }
                    >
                      {prest.cardPrice}
                    </h3>
                    <img src={arrow} id="arrow" alt="arrow-icon"></img>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      </div>

      <div id="quick-action-holder">
        <QuickActions></QuickActions>
      </div>
      <Questions
        questions={content.questions}
        surtitle={content.subTitle}
      ></Questions>
    </Layout>
  );
}

// we query for the node with the matching slug
export const query = graphql`
  query ($slug: String!) {
    allDomainsJson(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          slug
          subTitle
          title
          text
          metaText
          checkboxes
          prestations {
            slug
            cardTitle
            cardText
            cardPrice
          }
          questions {
            name
            description
          }
        }
      }
    }
  }
`;
