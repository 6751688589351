import React, { useState } from "react";
import "./Questions.sass";

const Questions = (props) => {
  const [openedQuestion, setOpenedQuestion] = useState(-1);

  const altClick = (i) => {
    if (i == openedQuestion) {
      setOpenedQuestion(-1);
    } else {
      setOpenedQuestion(i);
    }
  };

  const questions = props.questions;
  // questions.splice(0,6);
  let split = false;
  let splitIndex = questions.length;
  let questionsClass = "questions same-line";
  if (questions.length > 5) {
    split = true;
    questionsClass = "questions new-line";
    splitIndex = Math.floor(questions.length / 2) + (questions.length % 2);
  }

  return (
    <div className={questionsClass}>
      <div id="header-container">
        <h5>{props.surtitle}</h5>
        <h2>Questions fréquentes</h2>
        <p>
          Consultez les questions les plus courantes et trouvez la solution à
          votre problème.
        </p>
      </div>
      <div id="questions-container">
        <div className="questions-holder">
          {questions.map((question, i) => {
            if (i < splitIndex) {
              let classNameCont =
                openedQuestion === i
                  ? "question-name-desc-container expanded"
                  : "question-name-desc-container";
              return (
                <div className="question-container">
                  <div className="question-number">
                    {i < 9 ? "0" : ""}
                    {i + 1}
                  </div>
                  <div className={classNameCont}>
                    <div
                      className="question-name"
                      onClick={() => {
                        altClick(i);
                      }}
                    >
                      {question.name}
                    </div>
                    <div className="question-description">
                      {question.description}
                    </div>
                  </div>
                  {openedQuestion === i ? (
                    <div
                      role="button"
                      tabIndex={0}
                      onClick={() => {
                        setOpenedQuestion(-1);
                      }}
                      className="expand-button"
                    >
                      -
                    </div>
                  ) : (
                    <div
                      role="button"
                      tabIndex={0}
                      onClick={() => {
                        setOpenedQuestion(i);
                      }}
                      className="expand-button"
                    >
                      +
                    </div>
                  )}
                </div>
              );
            } else {
              return "";
            }
          })}
        </div>
        {split ? (
          <div className="questions-holder">
            {questions.map((question, i) => {
              if (i >= splitIndex) {
                let classNameCont =
                  openedQuestion === i
                    ? "question-name-desc-container expanded"
                    : "question-name-desc-container";
                return (
                  <div className="question-container">
                    <div className="question-number">
                      {i < 9 ? "0" : ""}
                      {i + 1}
                    </div>
                    <div className={classNameCont}>
                      <div
                        className="question-name"
                        onClick={() => {
                          altClick(i);
                        }}
                      >
                        {question.name}
                      </div>
                      <div className="question-description">
                        {question.description}
                      </div>
                    </div>
                    {openedQuestion === i ? (
                      <div
                        role="button"
                        tabIndex={0}
                        onClick={() => {
                          setOpenedQuestion(-1);
                        }}
                        className="expand-button"
                      >
                        -
                      </div>
                    ) : (
                      <div
                        role="button"
                        tabIndex={0}
                        onClick={() => {
                          setOpenedQuestion(i);
                        }}
                        className="expand-button"
                      >
                        +
                      </div>
                    )}
                  </div>
                );
              } else {
                return "";
              }
            })}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Questions;
